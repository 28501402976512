import GitHubIcon from "@mui/icons-material/GitHub";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Alert from "@mui/joy/Alert";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Link from "@mui/joy/Link";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useEffect, useState } from "react";
import {
  Deployment,
  GitConnection,
  ServiceStack,
  StackStatus,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import GitLabIcon from "../../../components/logos/gitlab-logo-500.svg";
import BitBucketIcon from "../../../components/logos/bitbucket-logo.svg";
import { PageLoading } from "../../../components/page_loading";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";

export const DeployCdnServiceComponent = (props: {
  loading: boolean;
  stack: ServiceStack;
  deployment: Deployment | null;
  stackStatus: StackStatus | null;
  gitConnectionArns: GitConnection[];
  queryError: string[];
  removeQueryError: (i: number) => void;
  deployCdnService: (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    buildtimeJson: string,
    webHostname: string,
    altFullDomainName: string,
    altCertificateArn: string
  ) => void;
  refetchStackStatus: () => void;
  removeCdnService: () => void;
  amIRoot: boolean;
}) => {
  const {
    loading,
    stack,
    deployment,
    stackStatus,
    gitConnectionArns,
    queryError,
    removeQueryError,
    deployCdnService,
    refetchStackStatus,
    removeCdnService,
    amIRoot,
  } = props;
  const [gitConnectionArn, setGitConnectionArn] = useState<string>("");
  const [gitRepositoryId, setGitRepositoryId] = useState<string>("");
  const [gitBranch, setGitBranch] = useState<string>("");
  const [buildspecPath, setBuildspecPath] = useState<string>("");
  const [buildtimeJson, setBuildtimeJson] = useState<string>("");
  const [webHostname, setWebHostname] = useState<string>("");
  const [altFullDomainName, setAltFullDomainName] = useState<string>("");
  const [altCertificateArn, setAltCertificateArn] = useState<string>("");

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (stackStatus !== null && (stackStatus.parametersJSON || null) !== null) {
      try {
        const params = JSON.parse(stackStatus.parametersJSON!);
        setGitConnectionArn(params.GitConnectionArn || "");
        setGitRepositoryId(params.GitRepositoryId || "");
        setGitBranch(params.GitBranch || "");
        setBuildspecPath(params.BuildspecPath || "");
        setBuildtimeJson(params.BuildEnvVarsJson || "");
        setWebHostname(params.WebHostname || "");
        setAltFullDomainName(params.AltFullDomainName || "");
        setAltCertificateArn(params.AltCertificateArn || "");
      } catch (err) {
        console.info("Failed to parse parametersJSON");
      }
    }
  }, [stackStatus]);

  return (
    <>
      <PageLoading loading={loading} />
      {queryError.map((e, i) => (
        <Alert
          key={i}
          color="danger"
          variant="soft"
          endDecorator={
            <Button
              color="danger"
              variant="soft"
              onClick={() => removeQueryError(i)}
            >
              DISMISS
            </Button>
          }
          sx={{ mb: 2 }}
        >
          Error: {e}
        </Alert>
      ))}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {!loading && (stackStatus?.status || null) === null && (
          <Grid xs={12}>
            <Alert color="neutral" variant="soft" sx={{ mb: 2 }}>
              The service has not been deployed to this environment yet.
            </Alert>
          </Grid>
        )}
        {!loading &&
          stackStatus?.status !== null &&
          stackStatus?.status !== undefined && (
            <Grid xs={12}>
              <Alert
                color={
                  stackStatus?.status === "CREATE_COMPLETE" ||
                  stackStatus?.status === "UPDATE_COMPLETE"
                    ? "success"
                    : (stackStatus?.status || "").indexOf("CREATE") > -1 ||
                      (stackStatus?.status || "").indexOf("UPDATE") > -1
                    ? "primary"
                    : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                    ? "danger"
                    : "neutral"
                }
                variant="soft"
                sx={{ mb: 2 }}
                startDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ? (
                    <CircularProgress
                      size="sm"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                    />
                  ) : null
                }
                endDecorator={
                  (stackStatus?.status || "").indexOf("IN_PROGRESS") === -1 ? (
                    <Button
                      size="sm"
                      variant="soft"
                      color={
                        stackStatus?.status === "CREATE_COMPLETE" ||
                        stackStatus?.status === "UPDATE_COMPLETE"
                          ? "success"
                          : (stackStatus?.status || "").indexOf("CREATE") >
                              -1 ||
                            (stackStatus?.status || "").indexOf("UPDATE") > -1
                          ? "primary"
                          : (stackStatus?.status || "").indexOf("ROLLBACK") > -1
                          ? "danger"
                          : "neutral"
                      }
                      onClick={() => {
                        refetchStackStatus();
                      }}
                    >
                      Refresh
                    </Button>
                  ) : null
                }
              >
                Deployment status: {stackStatus?.status}
                <Link
                  href={`https://${deployment?.awsRegion}.console.aws.amazon.com/cloudformation/home?region=${deployment?.awsRegion}#/stacks/stackinfo?filteringText=&filteringStatus=active&viewNested=true&stackId=waffle-${deployment?.id}-${stack?.id}-cdn-cicd`}
                >
                  Details
                </Link>
              </Alert>
            </Grid>
          )}
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Select your git connection</FormLabel>
            <Select
              placeholder="Select Git Connection"
              value={gitConnectionArn}
              slotProps={{
                listbox: {
                  sx: {
                    maxHeight: 300,
                    overflow: "auto", // required for scrolling
                    zIndex: 1201, // Because of the floating drawer on mobile
                  },
                },
              }}
            >
              {gitConnectionArns?.map((conn, i) => (
                <Option
                  value={conn.id}
                  key={conn.id || i}
                  onClick={() => {
                    setGitConnectionArn(conn.id || "");
                  }}
                >
                  <ListItemDecorator>
                    {conn.providerType === "GitHub" ||
                    conn.providerType === "GitHubEnterpriseServer" ? (
                      <GitHubIcon />
                    ) : conn.providerType === "Bitbucket" ? (
                      <Avatar src={BitBucketIcon} />
                    ) : (
                      <Avatar src={GitLabIcon} />
                    )}
                  </ListItemDecorator>
                  {conn.name}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              Select the connection to your git provider that has the repository
              that you want to deploy
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Git Repository</FormLabel>
            <Input
              value={gitRepositoryId}
              onChange={(event) => {
                setGitRepositoryId(event.target.value);
              }}
            />
            <FormHelperText>
              The owner and name of the repo expected in the following format:
              some-user/my-repo
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormLabel>Git Branch</FormLabel>
          <Input
            value={gitBranch}
            onChange={(event) => {
              setGitBranch(event.target.value);
            }}
          />
          <FormHelperText>The branch in the repo to deploy</FormHelperText>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Buildpsec path</FormLabel>
            <Input
              value={buildspecPath}
              onChange={(event) => {
                setBuildspecPath(event.target.value);
              }}
            />
            <FormHelperText>
              The path in the repo to the buildspec.yml file with build
              instructrions. The path has to include the filename as well.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Build-time JSON</FormLabel>
            <Input
              value={buildtimeJson}
              onChange={(event) => {
                setBuildtimeJson(event.target.value);
              }}
            />
            <FormHelperText>
              An optional JSON string that you can send access when building the
              container as an environmental variable. Unsure? Leave it empty.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Website Hostname</FormLabel>
            <Input
              value={webHostname}
              placeholder="www"
              onChange={(event) => {
                setWebHostname(event.target.value);
              }}
            />
            <FormHelperText>
              If you'd like to deploy this frontend to www.
              {deployment?.fullDomainName || "dev.example.com"}, then this value
              would be "www". Additionally you can deploy this frontend with a
              custom domain name too, see the next fields below.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Alternative Domain Name</FormLabel>
            <Input
              value={altFullDomainName}
              onChange={(event) => {
                setAltFullDomainName(event.target.value);
              }}
            />
            <FormHelperText>
              If you'd like to deploy this frontend to a different hostname,
              like mycompletely.differentdomain.com then you can specify that
              here. Heads up: it only works if you can create an SSL certificate
              for that in this AWS account. Waffle doesn't automatically do
              that.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <FormLabel>Alternative Domain SSL Certificate ARN</FormLabel>
            <Input
              value={altCertificateArn}
              onChange={(event) => {
                setAltCertificateArn(event.target.value);
              }}
            />
            <FormHelperText>
              If you have specified an alternative domain name then you need to
              create a certificate for that using the AWS certificate manager in
              this ({deployment?.name || deployment?.id || ""}) account, and
              enter its ARN here.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <Button
            onClick={() =>
              deployCdnService(
                gitConnectionArn,
                gitRepositoryId,
                gitBranch,
                buildspecPath,
                buildtimeJson,
                webHostname,
                altFullDomainName,
                altCertificateArn
              )
            }
            disabled={
              loading || (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1
            }
          >
            {(stackStatus?.status || null) === null ? "Deploy" : "Update"}{" "}
            Service
          </Button>
          {(stackStatus?.status || null) !== null && amIRoot && (
            <Button
              onClick={() => setOpenModal(true)}
              disabled={
                loading ||
                (stackStatus?.status || "").indexOf("IN_PROGRESS") > -1 ||
                (stackStatus?.status || "").indexOf("DELETE_FAILED") > -1
              }
              variant="plain"
              color="neutral"
            >
              Remove Service
            </Button>
          )}
        </Grid>
      </Grid>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            Are you sure to remove the service?
          </DialogTitle>
          <DialogContent>
            This action will remove the service and all associated resources
            including the CICD pipeline.
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              color="danger"
              onClick={() => removeCdnService()}
            >
              Remove Service
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};
