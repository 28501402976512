import { useContext } from "react";
import { ProfileComponent } from "./component";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  useGetMyOrganizationQuery,
  useWhoAmIQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertsContext } from "../../contexts/alerts/context";
import { AlertType } from "../../contexts/alerts/type";

export const ProfileContainer = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });

  const { data: organizationData, loading: organizationLoading } =
    useGetMyOrganizationQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.WARNING,
          });
        }
      },
    });

  return (
    <ProfileComponent
      loading={whoAmILoading || organizationLoading}
      user={whoAmIData?.whoAmI || null}
      organization={organizationData?.getMyOrganization || null}
      signOut={signOut}
    />
  );
};
