import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  Deployment,
  useCreateMyWaffleFullDomainNameNsRecordMutation,
  useGetMyFullDomainNameHostedZonesQuery,
  useGetMyWaffleFullDomainNameNsRecordQuery,
  useGetMyWaffleFullDomainNameNsRecordStatusLazyQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { StackSummaryComponent } from "./stack_summary";

export const NsRecordDeployer = (props: {
  deploymentId: string;
  deployment: Deployment | null;
  deploymentEnabled: boolean;
  setStackCompleted: (status: boolean) => void;
}) => {
  const { deploymentId, deploymentEnabled, setStackCompleted } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);
  const [changeId, setChangeId] = useState<string | null>(null);

  const {
    data: getMyFullDomainNameHostedZonesData,
    loading: getMyFullDomainNameHostedZonesLoading,
  } = useGetMyFullDomainNameHostedZonesQuery({
    client: bgwService.getClient(),
    variables: { deploymentId },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const {
    data: getMyWaffleFullDomainNameNsRecordData,
    loading: getMyWaffleFullDomainNameNsRecordLoading,
    refetch: getMyWaffleFullDomainNameNsRecordRefetch,
  } = useGetMyWaffleFullDomainNameNsRecordQuery({
    variables: {
      deploymentId,
    },
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const recordExists =
    getMyWaffleFullDomainNameNsRecordData?.getMyWaffleFullDomainNameNsRecord;

  const [
    getMyWaffleFullDomainNameNsRecordStatus,
    {
      data: getMyWaffleFullDomainNameNsRecordStatusData,
      loading: getMyWaffleFullDomainNameNsRecordStatusLoading,
      refetch: getMyWaffleFullDomainNameNsRecordStatusRefetch,
    },
  ] = useGetMyWaffleFullDomainNameNsRecordStatusLazyQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const recordStatus =
    getMyWaffleFullDomainNameNsRecordStatusData?.getMyWaffleFullDomainNameNsRecordStatus;

  const [triggeringStack, setTriggeringStack] = useState<boolean>(false);

  const [
    createMyWaffleFullDomainNameNsRecord,
    { loading: createMyWaffleFullDomainNameNsRecordLoading },
  ] = useCreateMyWaffleFullDomainNameNsRecordMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
    onCompleted: (data) => {
      const newChangeId = data?.createMyWaffleFullDomainNameNsRecord || null;
      if (newChangeId) {
        setChangeId(newChangeId);
      }
      // setChangeId(data?.createMyWaffleFullDomainNameNsRecord || null);
      setTimeout(async () => {
        await getMyWaffleFullDomainNameNsRecordRefetch();
        // setTriggeringStack(false);
        if (newChangeId) {
          getMyWaffleFullDomainNameNsRecordStatus({
            variables: {
              deploymentId,
              changeInfoId: newChangeId,
            },
          });
        }
      }, 3000);
    },
  });

  // Start deployment
  useEffect(() => {
    if (
      !recordExists &&
      !triggeringStack &&
      deploymentEnabled &&
      getMyFullDomainNameHostedZonesData !== undefined &&
      getMyFullDomainNameHostedZonesData !== null &&
      (getMyFullDomainNameHostedZonesData!.getMyFullDomainNameHostedZones
        ?.length || 0) > 0
    ) {
      setTriggeringStack(true);
      createMyWaffleFullDomainNameNsRecord({
        variables: {
          deploymentId,
          nameServers:
            getMyFullDomainNameHostedZonesData!
              .getMyFullDomainNameHostedZones![0]!.nameServers!,
        },
      });
    }
  }, [
    recordExists,
    deploymentEnabled,
    createMyWaffleFullDomainNameNsRecord,
    deploymentId,
    getMyFullDomainNameHostedZonesData,
    triggeringStack,
  ]);

  // Check deployment progress
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!recordExists || recordStatus !== "DONE") {
        getMyWaffleFullDomainNameNsRecordRefetch();
        if (changeId) {
          getMyWaffleFullDomainNameNsRecordStatusRefetch({
            deploymentId,
            changeInfoId: changeId,
          });
        }
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [
    recordExists,
    recordStatus,
    deploymentId,
    getMyWaffleFullDomainNameNsRecordRefetch,
    getMyWaffleFullDomainNameNsRecordStatusRefetch,
    changeId,
  ]);

  // Callback
  useEffect(() => {
    console.info("recordStatus", recordStatus, "recordExists", recordExists);
    // setStackCompleted(recordStatus ? recordStatus === "DONE" : !!recordExists);
    setStackCompleted(recordExists || recordStatus === "DONE");
  }, [recordStatus, recordExists, setStackCompleted]);

  return (
    <StackSummaryComponent
      loading={
        getMyFullDomainNameHostedZonesLoading ||
        getMyWaffleFullDomainNameNsRecordLoading ||
        getMyWaffleFullDomainNameNsRecordStatusLoading ||
        createMyWaffleFullDomainNameNsRecordLoading
      }
      deploymentEnabled={deploymentEnabled}
      title="Waffle Administration"
      link={null}
      status={
        recordStatus ||
        (recordExists === undefined
          ? undefined
          : !recordExists
          ? "TO_BE_DONE"
          : "DONE")
      }
    />
  );
};
