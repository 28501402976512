import { CfnRoleContainer } from "./role/container";
import { GitConnectionContainer } from "./git_connection/container";
import { AddServiceContainer } from "./add_service/container";
import { DeployDeploymentStacksContainer } from "./deploy_deployment_stacks/container";

export type StepDef = {
  id: string;
  title: string;
  component: (props: {
    deploymentId: string;
    navigateToNext: null | (() => void);
    navigateToPrevious: null | (() => void);
    navigateWhenFinished: () => void;
  }) => JSX.Element;
};

export const ONBOARDING_STEPS: StepDef[] = [
  {
    id: "role",
    title: "AWS Connection",
    component: CfnRoleContainer,
  },
  {
    id: "git_connection",
    title: "Git Connection",
    component: GitConnectionContainer,
  },
  {
    id: "deploy_defaults",
    title: "Environment",
    component: DeployDeploymentStacksContainer,
  },
  {
    id: "add_ecs",
    title: "Service",
    component: AddServiceContainer,
  },
];
