import { useContext, useEffect, useState } from "react";
import {
  Deployment,
  ServiceStack,
  useDeployMyCdnStackMutation,
  useGetMyCdnServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
  useRemoveMyCdnStackMutation,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployCdnServiceComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertType } from "../../../contexts/alerts/type";
import { AlertsContext } from "../../../contexts/alerts/context";

export const DeployCdnServiceContainer = (props: {
  deployment: Deployment;
  stack: ServiceStack;
}) => {
  const { deployment, stack } = props;
  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    error: getMyGitConnectionsError,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
    },
  });

  useEffect(() => {
    if ((getMyGitConnectionsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyGitConnectionsError!.message]);
    }
  }, [getMyGitConnectionsError]);

  const {
    data: getMyCdnServiceStackStatusData,
    loading: getMyCdnServiceStackStatusLoading,
    error: getMyCdnServiceStackStatusError,
    refetch: getMyCdnServiceStackStatusRefetch,
  } = useGetMyCdnServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
      pipelineId: stack.id,
    },
  });

  useEffect(() => {
    if ((getMyCdnServiceStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyCdnServiceStackStatusError!.message]);
    }
  }, [getMyCdnServiceStackStatusError]);

  const [deployMyCdnStackMutation, { loading: deployMyCdnStackLoading }] =
    useDeployMyCdnStackMutation({
      client: bgwService.getClient(),
    });

  const deployCdnService = (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    buildtimeJson: string,
    webHostname: string,
    altFullDomainName: string,
    altCertificateArn: string
  ) => {
    deployMyCdnStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stack.id,
          deploymentId: deployment.id,
          gitConnectionArn,
          gitRepositoryId,
          gitBranch,
          buildspecPath,
          buildtimeJson,
          webHostname,
          altFullDomainName:
            altFullDomainName === "" ? null : altFullDomainName,
          altCertificateArn:
            altCertificateArn === "" ? null : altCertificateArn,
        },
      },
      // update(cache, { data }) {
      //   cache.modify({
      //     fields: {
      //       getMyCdnServiceStackStatus(existingnewStackStatuses = []) {
      //         const newStackStatuskRef = cache.writeFragment({
      //           data: data?.deployMyCdnStack,
      //           fragment: gql`
      //             fragment NewStackStatus on StackStatus {
      //               status
      //               parametersJSON
      //             }
      //           `,
      //         });
      //         return [...existingnewStackStatuses, newStackStatuskRef];
      //       },
      //     },
      //   });
      // },
      onCompleted: (data) => {
        getMyCdnServiceStackStatusRefetch();
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to deploy stack to ${deployment.id}.`,
        ]);
        console.error(error);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyCdnServiceStackStatusData?.getMyCdnServiceStackStatus?.status ||
          ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyCdnServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyCdnServiceStackStatusData, getMyCdnServiceStackStatusRefetch]);

  const [removeMyCdnStack, { loading: removeMyCdnStackLoading }] =
    useRemoveMyCdnStackMutation({
      client: bgwService.getClient(),
    });

  const removeCdnService = () => {
    setMutationInProgress(true);
    removeMyCdnStack({
      variables: {
        stackId: null,
        deploymentId: deployment.id,
        pipelineId: stack.id,
      },
      onCompleted: () => {
        getMyCdnServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to remove stack from ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const amIRoot = whoAmIData?.whoAmI?.role === "ROOT" || false;

  return (
    <DeployCdnServiceComponent
      loading={
        getMyGitConnectionsLoading ||
        getMyCdnServiceStackStatusLoading ||
        deployMyCdnStackLoading ||
        removeMyCdnStackLoading ||
        mutationInProgress ||
        whoAmILoading
      }
      stack={stack}
      deployment={deployment}
      stackStatus={
        getMyCdnServiceStackStatusData?.getMyCdnServiceStackStatus || null
      }
      gitConnectionArns={getMyGitConnectionsData?.getMyGitConnections || []}
      queryError={queryError}
      removeQueryError={removeQueryError}
      deployCdnService={deployCdnService}
      refetchStackStatus={() => {
        getMyCdnServiceStackStatusRefetch();
      }}
      removeCdnService={removeCdnService}
      amIRoot={amIRoot}
    />
  );
};
