import { useContext, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AddNewDeploymentComponent } from "./component";
import {
  useAddMyDeploymentMutation,
  useGetMyDeploymentsQuery,
  useSetMyOrganizationMutation,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { gql } from "@apollo/client";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const AddNewDeploymentContainer = (props: {
  navigateToNext: null | ((deploymentId: string) => void);
}) => {
  const { navigateToNext } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
  });
  const iBelongToOrganization = whoAmIData?.whoAmI?.organizationId !== null;

  const {
    data: getMyDeploymentsData,
    loading: getMyDeploymentsLoading,
    error: getMyDeploymentsError,
  } = useGetMyDeploymentsQuery({
    client: bgwService.getClient(),
  });

  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

  const [addMyDeploymentMutation, { loading: addMyDeploymentMutationLoading }] =
    useAddMyDeploymentMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const [setMyOrganization, { loading: setMyOrganizationLoading }] =
    useSetMyOrganizationMutation({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });
  const addDeployment = (
    accountId: string,
    awsRegion: string,
    deploymentId: string,
    deploymentName: string,
    organizationName: string
  ) => {
    const storeDeployment = () => {
      if ((getMyDeploymentsData?.getMyDeployments || []).length > 0) {
        if (navigateToNext) {
          navigateToNext(getMyDeploymentsData!.getMyDeployments![0].id);
        }
      }
      addMyDeploymentMutation({
        variables: {
          deploymentInput: {
            id: deploymentId,
            name: deploymentName,
            accountId,
            awsRegion,
          },
        },
        update(cache, { data }) {
          cache.modify({
            fields: {
              getMyDeployments(existingDeployments = []) {
                const newDeploymentRef = cache.writeFragment({
                  data: data?.addMyDeployment,
                  fragment: gql`
                    fragment NewDeployment on Deployment {
                      id
                      name
                      accountId
                      awsRegion
                    }
                  `,
                });
                return [...existingDeployments, newDeploymentRef];
              },
            },
          });
        },
        refetchQueries: ["GetMyDeployments"],
        onCompleted: (data) => {
          setMutationInProgress(false);
          if (data?.addMyDeployment !== undefined && navigateToNext) {
            navigateToNext(data!.addMyDeployment!.id);
          }
        },
      });
    };

    if (!iBelongToOrganization) {
      const organizationIdArray = organizationName
        .toLocaleLowerCase()
        .match(/[a-z0-9]/g);
      const organizationId = (organizationIdArray || []).join("");
      if (organizationId === "") {
        addAlert({
          text: "Please use letters in the organization name",
          type: AlertType.DANGER,
        });
        return;
      }
      setMutationInProgress(true);
      setMyOrganization({
        variables: {
          organizationName,
        },
        optimisticResponse: () => ({
          setMyOrganization: {
            id: organizationId,
            name: organizationName,
            onboardingComplete: false,
            features: {
              singleDeployment: true,
            },
          },
        }),
        update(cache, { data }) {
          cache.modify({
            fields: {
              getMyOrganization() {
                if (data?.setMyOrganization) {
                  const newOrganizationRef = cache.writeFragment({
                    id: `Organization:${data.setMyOrganization.id}`,
                    data: data.setMyOrganization,
                    fragment: gql`
                      fragment OrganizationDetail on Organization {
                        id
                        name
                        onboardingComplete
                        features {
                          singleDeployment
                        }
                      }
                    `,
                  });
                  return newOrganizationRef!;
                }
                return null;
              },
              getAllOrganizations(existingOrganizations = []) {
                const newOrganizationRef = cache.writeFragment({
                  data: data?.setMyOrganization,
                  fragment: gql`
                    fragment NewOrganization on Organization {
                      id
                      name
                    }
                  `,
                });
                return [...existingOrganizations, newOrganizationRef];
              },
            },
          });
        },
        refetchQueries: ["getMyOrganization", "getAllOrganizations"],
        onCompleted: () => {
          storeDeployment();
        },
      });
    } else {
      storeDeployment();
    }
  };

  if (getMyDeploymentsError) {
    console.error(getMyDeploymentsError);
  }

  return (
    <AddNewDeploymentComponent
      loading={
        whoAmILoading ||
        getMyDeploymentsLoading ||
        addMyDeploymentMutationLoading ||
        setMyOrganizationLoading ||
        mutationInProgress
      }
      deployments={getMyDeploymentsData?.getMyDeployments || []}
      addDeployment={addDeployment}
      iBelongToOrganization={iBelongToOrganization}
    />
  );
};
