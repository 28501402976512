import ConstructionIcon from "@mui/icons-material/Construction";
import DomainIcon from "@mui/icons-material/Domain";
import PublicIcon from "@mui/icons-material/Public";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Typography from "@mui/joy/Typography";
import { useEffect, useState } from "react";
import { PageLoading } from "../../../components/page_loading";
import { awsRegions } from "../../../configs/aws_regions";
import { Deployment } from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { LeanLayout } from "../layout";
import Grid from "@mui/joy/Grid";
import Stack from "@mui/joy/Stack";

export const AddNewDeploymentComponent = (props: {
  loading: boolean;
  deployments: Deployment[];
  addDeployment: (
    accountId: string,
    awsRegion: string,
    deploymentId: string,
    deploymentName: string,
    organizationName: string
  ) => void;
  iBelongToOrganization: boolean;
}) => {
  const { loading, deployments, addDeployment, iBelongToOrganization } = props;

  const [deploymentId, setDeploymentId] = useState<string>("prod");
  const [accountId, setAccountId] = useState<string>("");
  const [awsRegion, setAwsRegion] = useState<string>("us-east-1");

  const [organizationName, setOrganizationName] = useState<string>("");
  const validId =
    organizationName === "" || /^[a-z,A-Z,0-9, ]+$/.test(organizationName);

  const [readOnly, setReadOnly] = useState<boolean>(false);

  useEffect(() => {
    if (deployments.length > 0) {
      const deployment = deployments[0];
      setDeploymentId(deployment.id);
      setAccountId(deployment.accountId || "");
      setAwsRegion(deployment.awsRegion || "us-east-1");
      setReadOnly(true);
    }
  }, [deployments]);

  return (
    <LeanLayout>
      <PageLoading loading={loading} />

      <Typography level="h2" sx={{ pb: 2 }}>
        Deploy infrastructure to your AWS account
      </Typography>
      <Typography level="body-md" sx={{ py: 1 }}>
        Waffle helps deploy web front-ends, backends and databases in secure
        environments in a compliant way including CICD pipelines and monitoring.
      </Typography>
      <Typography level="body-md" sx={{ py: 1, pb: 3 }}>
        Let's start with setting up a secure environment and deploy a service
        into it. Only takes a few minutes.
      </Typography>

      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {!iBelongToOrganization && (
          <Grid xs={12}>
            <FormControl>
              <FormLabel>The name of your organization</FormLabel>
              <Stack spacing={0.5}>
                <Input
                  disabled={readOnly}
                  startDecorator={<DomainIcon />}
                  placeholder="My Org"
                  value={organizationName}
                  onChange={(event) => setOrganizationName(event.target.value)}
                />
                <Typography level="body-xs" sx={{ alignSelf: "flex-end" }}>
                  {validId ? "" : "Invalid value"}
                </Typography>
              </Stack>
            </FormControl>{" "}
          </Grid>
        )}

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Your AWS Account #</FormLabel>
            <Input
              disabled={readOnly}
              value={accountId}
              onChange={(event) => {
                setAccountId(event.target.value);
              }}
            />
            <FormHelperText>
              The ID of the AWS account you're planning to deploy into. If
              you're deploying to a sub-account of an AWS organization, then
              specify the sub-account's ID.
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>AWS Region</FormLabel>
            <Select
              value={awsRegion}
              disabled={readOnly}
              onChange={(
                event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                newValue && setAwsRegion(newValue!);
              }}
            >
              {awsRegions.map((r) => (
                <Option value={r} key={r}>
                  {r}
                </Option>
              ))}
            </Select>
            <FormHelperText>
              Select which AWS region shall your web-server live in
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <FormLabel>Deployment Purpose</FormLabel>
            <Select
              value={deploymentId}
              disabled={readOnly}
              onChange={(
                event: React.SyntheticEvent | null,
                newValue: string | null
              ) => {
                newValue && setDeploymentId(newValue!);
              }}
            >
              <Option value="prod">
                <ListItemDecorator>
                  <Avatar size="sm">
                    <PublicIcon />
                  </Avatar>
                </ListItemDecorator>
                Production
              </Option>
              <Option value="dev">
                <ListItemDecorator>
                  <Avatar size="sm">
                    <ConstructionIcon />
                  </Avatar>
                </ListItemDecorator>
                Development
              </Option>
            </Select>
            <FormHelperText>
              Chooses default settings that you can change anytime later.
            </FormHelperText>
          </FormControl>{" "}
        </Grid>
      </Grid>

      <Button
        sx={{ mt: 4 }}
        disabled={loading || accountId === ""}
        onClick={() =>
          addDeployment(
            accountId,
            awsRegion,
            deploymentId,
            deploymentId === "dev" ? "Development" : "Production",
            organizationName
          )
        }
      >
        Let's go
      </Button>
    </LeanLayout>
  );
};
