import { ReactNode, useContext, useMemo } from "react";
import {
  useGetMyOrganizationQuery,
  useWhoAmIQuery,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import { BgwContext } from "../../contexts/backend_gateway/context";
import { Loading } from "../../pages/loading";
import { matchRoutes, useLocation } from "react-router-dom";

export const OnboarderContainer = (props: {
  onboardingComponent: ReactNode;
  selectCompanyComponent: ReactNode;
  children: ReactNode;
}) => {
  const {
    onboardingComponent,
    // selectCompanyComponent,
    children,
  } = props;
  const { bgwService } = useContext(BgwContext);
  const { data: getMyOrganization, loading: getMyOrganizationLoading } =
    useGetMyOrganizationQuery({
      client: bgwService.getClient(),
    });

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
  });
  // const iBelongToOrganization = whoAmIData?.whoAmI?.organizationId !== null;

  const iAmOnboarded = useMemo(
    () =>
      whoAmIData?.whoAmI?.role === "ROOT" ||
      getMyOrganization?.getMyOrganization?.onboardingComplete,
    [getMyOrganization, whoAmIData]
  );

  const location = useLocation();

  const match = matchRoutes(
    [
      { path: "/onboarding" },
      { path: "/onboarding/:deploymentId/:stepId" },
      // { path: "/organization_setup" },
      // { path: "/organization_setup/:stepId" },
    ],
    location
  );
  if (match !== null) {
    return children;
  }

  if (getMyOrganizationLoading || whoAmILoading) {
    return <Loading />;
  }

  // if (!iBelongToOrganization) {
  //   return selectCompanyComponent;
  // }

  if (!iAmOnboarded) {
    return onboardingComponent;
  }

  return children;
};
