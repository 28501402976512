import {
  Authenticator,
  Grid,
  Heading,
  // Image,
  ThemeProvider,
  Theme,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
// import logo from "./logo.svg";

export const AuthenticatorPage = () => {
  const { tokens } = useTheme();
  const theme: Theme = {
    name: "Auth Theme",
    tokens: {
      components: {
        authenticator: {
          router: {
            boxShadow: `0 0 16px ${tokens.colors.overlay["10"]}`,
            borderWidth: "0",
          },
          form: {
            padding: `${tokens.space.medium} ${tokens.space.xl} ${tokens.space.medium}`,
          },
        },
        button: {
          primary: {
            backgroundColor: tokens.colors.neutral["100"],
          },
          link: {
            color: tokens.colors.neutral["80"],
          },
        },
        fieldcontrol: {
          _focus: {
            boxShadow: `0 0 0 2px ${tokens.colors.neutral["60"]}`,
          },
        },
        tabs: {
          item: {
            color: tokens.colors.neutral["80"],
            _active: {
              borderColor: tokens.colors.neutral["100"],
              color: tokens.colors.neutral["100"],
            },
          },
        },
      },
    },
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          templateColumns={{
            base: "1fr",
            // large: "1fr 1fr"
          }}
          templateRows="100%"
          gap={tokens.space.small}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            overflow: "hidden",
          }}
        >
          <View padding="xxl" backgroundColor={tokens.colors.neutral[20]}>
            <Grid templateColumns="1fr" templateRows="64px 1fr">
              <View textAlign="center">
                <Heading level={6}>Welcome to Waffle!</Heading>
              </View>
              <View>
                <Authenticator loginMechanisms={["email"]} />
              </View>
              {/* <View textAlign="center" style={{ paddingTop: 20 }}>
                <Image
                  alt="Waffle"
                  src={logo}
                  height="35%"
                  width="35%"
                  opacity="40%"
                />
              </View> */}
            </Grid>
          </View>
          {/* <View>
            <iframe
              src="https://www.wafflesoc2.com/"
              title="What is Waffle?"
              // frameBorder="0"
              style={{
                border: "0px solid transparent",
                overflow: "hidden",
                height: "100%",
                width: "100%",
              }}
              height="100%"
              width="100%"
            />
          </View> */}
        </Grid>
      </ThemeProvider>
    </>
  );
};
