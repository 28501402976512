import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import Grid from "@mui/joy/Grid";
import { PageFrame } from "../../components/page_frame";
import { PageLoading } from "../../components/page_loading";
import { PageTitle } from "../../components/page_title";
import {
  Deployment,
  ServiceStack,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Button from "@mui/joy/Button";

export const DashboardComponent = (props: {
  loading: boolean;
  deploymentId: string;
  deployment: Deployment | null;
  cloudTrailLink: string | null;
  codePipelineLink: string | null;
  secretsLink: string | null;
  cloudFrontLink: string | null;
  apiGatewayHostnameParameterLink: string | null;
  apiGatewayApiKeysLink: string | null;
  apiGatewayUsagePlansLink: string | null;
  services: ServiceStack[];
  getServiceCloudWatchLink: (service: ServiceStack) => string | null;
  getServiceHostnameParameterLink: (service: ServiceStack) => string | null;
  getServiceApiGatewayEndpoint: (service: ServiceStack) => string | null;
  deployApiGateway: () => void;
}) => {
  const {
    loading,
    deploymentId,
    deployment,
    cloudTrailLink,
    codePipelineLink,
    secretsLink,
    cloudFrontLink,
    // apiGatewayHostnameParameterLink,
    apiGatewayApiKeysLink,
    apiGatewayUsagePlansLink,
    services,
    getServiceCloudWatchLink,
    getServiceHostnameParameterLink,
    // getServiceApiGatewayEndpoint,
    deployApiGateway,
  } = props;
  return (
    <PageFrame>
      <PageTitle>{deployment?.name || deploymentId} Environment</PageTitle>
      <PageLoading loading={loading} />
      <Grid container spacing={2} sx={{ alignItems: "flex-start" }}>
        {services.map((service) => (
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={6}
            key={service.id}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            <Sheet
              variant="plain"
              sx={{
                borderRadius: 6,
                my: 2,
                mr: 2,
                p: 2,
              }}
            >
              <Typography
                level="title-lg"
                startDecorator={<MiscellaneousServicesIcon />}
              >
                {service.name || service.id}
              </Typography>
              {service.type === "ECS" && (
                <>
                  <Typography level="body-xs">
                    This service is deployed as a docker container with ECS
                    Fargate
                  </Typography>
                  <Sheet
                    variant="soft"
                    sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}
                  >
                    <Typography level="body-xs">
                      This service produces logs in CloudWatch.
                    </Typography>
                    <Typography level="body-sm">
                      {getServiceCloudWatchLink(service) && (
                        <Link href={getServiceCloudWatchLink(service)!}>
                          Link to CloudWatch Logs
                        </Link>
                      )}
                    </Typography>
                  </Sheet>
                  <Sheet
                    variant="soft"
                    sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}
                  >
                    <Typography level="body-xs">
                      When accessing this service from another backend service
                      using HTTP request, the typical way is to connect to the
                      service's Application Load Balancer (ALB). The ALB routes
                      the request to the underlying docker containers.
                    </Typography>
                    <Typography level="body-sm">
                      {getServiceHostnameParameterLink(service) && (
                        <Link href={getServiceHostnameParameterLink(service)!}>
                          Link to parameter where the ALB hostname is stored
                        </Link>
                      )}
                    </Typography>
                  </Sheet>
                  {/* <Sheet
                    variant="soft"
                    sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}
                  >
                    <Typography level="body-xs">
                      This service has a public endpoint on the ApiGateway.
                    </Typography>
                    <Typography level="body-sm">
                      {getServiceApiGatewayEndpoint(service) && (
                        <Link href={getServiceApiGatewayEndpoint(service)!}>
                          {getServiceApiGatewayEndpoint(service)!}
                        </Link>
                      )}
                    </Typography>
                  </Sheet> */}
                </>
              )}
              {service.type === "CFN" && (
                <Typography level="body-xs">
                  This is a service is a custom infrastructure. Its details
                  depend on what the infrastructure-as-code is that's being
                  deployed.
                </Typography>
              )}
              {service.type === "CDN" && (
                <>
                  <Typography level="body-xs">
                    This is a service is a web frontend. Static files served
                    with the public without authentication required.
                  </Typography>
                  <Sheet
                    variant="soft"
                    sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}
                  >
                    <Typography level="body-xs">
                      CloudFront is set up to act as a firewall and to serve the
                      files with low latency using strong caching.
                    </Typography>
                    <Typography level="body-sm">
                      {cloudFrontLink && (
                        <Link href={cloudFrontLink}>Link to CloudFront</Link>
                      )}
                    </Typography>
                  </Sheet>
                </>
              )}
            </Sheet>
          </Grid>
        ))}
        <Grid xs={12} sm={12} md={6} lg={6}>
          <Sheet variant="plain" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
            <Typography level="title-lg">CICD Pipelines</Typography>
            <Sheet variant="soft" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
              <Typography level="body-xs">
                Continuous integration and deployment is set up using
                CodePipeline, which pulls data from your git connection, uses
                CodeBuild build the sources, and deploys each service depending
                what their types is.
              </Typography>
              <Typography level="body-sm">
                {codePipelineLink && (
                  <Link href={codePipelineLink}>Link to CodePipeline</Link>
                )}
              </Typography>
            </Sheet>
          </Sheet>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6}>
          <Sheet variant="plain" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
            <Typography level="title-lg">API Gateway</Typography>
            <Typography level="body-xs">
              The API Gateway acts as a firewall and authenticator that provides
              controlled access to the privately hosted backend services.
            </Typography>
            <Sheet variant="soft" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
              <Typography level="body-xs">
                The API Gateway supports API Keys for machine access. When you
                create keys, make sure to apply them to an API Usage Plan.
              </Typography>
              <Typography level="body-sm">
                {apiGatewayApiKeysLink && (
                  <Link href={apiGatewayApiKeysLink}>Link to the API Keys</Link>
                )}
              </Typography>
            </Sheet>
            <Sheet variant="soft" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
              <Typography level="body-xs">
                Waffle provides a default usage plan, that provides access to
                all services on the API Gateway by default.
              </Typography>
              <Typography level="body-sm">
                {apiGatewayUsagePlansLink && (
                  <Link href={apiGatewayUsagePlansLink}>
                    Link to the Usage Plans
                  </Link>
                )}
              </Typography>
            </Sheet>
            <Sheet variant="soft" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
              <Typography level="body-xs">
                In order to change the API Gateway endpoints, the latest
                configuration has to be deployed. It can be done manually by
                clicking this button
              </Typography>
              <Button
                onClick={deployApiGateway}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                Deploy API Gateway
              </Button>
            </Sheet>
          </Sheet>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6}>
          <Sheet variant="plain" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
            <Typography level="title-lg">Secrets</Typography>
            <Typography level="body-xs">
              Secrets enable one to work with sensitive data without having to
              access the sensitive values. Waffle sets up a secret that's
              accessible for all backend services deployed by waffle. Waffle
              also sets up a private secret for every backend service too.
            </Typography>
            <Sheet variant="soft" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
              <Typography level="body-xs">
                Secrets are managed in the Secrets Manager.
              </Typography>
              <Typography level="body-sm">
                {secretsLink && (
                  <Link href={secretsLink}>Link to the Secrets Manager</Link>
                )}
              </Typography>
            </Sheet>
          </Sheet>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6}>
          <Sheet variant="plain" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
            <Typography level="title-lg">Security logs</Typography>
            <Sheet variant="soft" sx={{ borderRadius: 6, my: 2, mr: 2, p: 2 }}>
              <Typography level="body-xs">
                All events that are happening in an AWS account can be logged
                using CloudTrail. Waffle doesn't set this up, as it would be a
                security risk if Waffle could access the trail logs. But it's
                highly recommended to set it up, if haven't yet.
              </Typography>
              <Typography level="body-sm">
                {cloudTrailLink && (
                  <Link href={cloudTrailLink}>Link to CloudTrail events</Link>
                )}
              </Typography>
            </Sheet>
          </Sheet>
        </Grid>
      </Grid>
    </PageFrame>
  );
};
