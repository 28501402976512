import { useContext, useEffect, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import {
  Deployment,
  ServiceStack,
  useDeployMyDbStackMutation,
  useGetMyDbEngineVersionsLazyQuery,
  useGetMyDbFamiliesLazyQuery,
  useGetMyDbInstanceClassesLazyQuery,
  useGetMyDbServiceStackStatusQuery,
  useGetMyDbSnapshotIdsQuery,
  useRemoveMyDbStackMutation,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";
import { DeployDbServiceComponent } from "./component";

export const DeployDbServiceContainer = (props: {
  deployment: Deployment;
  stack: ServiceStack;
}) => {
  const { deployment, stack } = props;
  const [queryError, setQueryError] = useState<string[]>([]);

  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyDbServiceStackStatusData,
    loading: getMyDbServiceStackStatusLoading,
    refetch: getMyDbServiceStackStatusRefetch,
  } = useGetMyDbServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
      databaseId: stack.id,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);

  const [deployMyDbStackMutation, { loading: deployMyDbStackLoading }] =
    useDeployMyDbStackMutation({
      client: bgwService.getClient(),
    });

  const [dbType, setDbType] = useState<string>("RDS");
  const [family, setFamily] = useState<string>("");
  const [engineVersion, setEngineVersion] = useState<string>("");
  const [instanceClass, setInstanceClass] = useState<string>("");
  const [snapshotId, setSnapshotId] = useState<string>("");
  const [multiAz, setMultiAz] = useState<boolean>(false);
  const [alarmsEnabled, setAlarmsEnabled] = useState<boolean>(true);
  const [allocatedStorage, setAllocatedStorage] = useState<number>(10);
  const [backupRetentionPeriod, setBackupRetentionPeriod] = useState<number>(7);
  const [logRetentionDays, setLogRetentionDays] = useState<number>(30);
  const [createReplica, setCreateReplica] = useState<boolean>(false);

  const deployDbService = () => {
    setMutationInProgress(true);
    deployMyDbStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stack.id,
          deploymentId: deployment.id,
          alarmsEnabled,
          allocatedStorage,
          backupRetentionPeriod,
          logRetentionDays,
          createReplica,
          dbType,
          family,
          engineVersion,
          instanceClass,
          multiAz,
          snapshotId,
        },
      },
      onCompleted: () => {
        getMyDbServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to deploy stack to ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyDbServiceStackStatusData?.getMyDbServiceStackStatus?.status || ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyDbServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyDbServiceStackStatusData, getMyDbServiceStackStatusRefetch]);

  const [availableFamilies, setAvailableFamilies] = useState<string[]>([]);
  const [availableEngineVersions, setAvailableEngineVersions] = useState<
    string[]
  >([]);
  const [availableInstanceClasses, setAvailableInstanceClasses] = useState<
    string[]
  >([]);
  const [availableSnapshotIds, setAvailableSnapshotIds] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (getMyDbServiceStackStatusData) {
      const params = JSON.parse(
        getMyDbServiceStackStatusData.getMyDbServiceStackStatus
          ?.parametersJSON || "{}"
      );
      setDbType((params?.DBType || "RDS").toUpperCase());
      setFamily(params?.Family || "");
      setEngineVersion(params?.PostgresEngineVersion || "");
      setInstanceClass(params?.InstanceClass || "");
      setSnapshotId(params?.SnaphotId || "");
      setMultiAz(params?.MultiAZ === "True");
      setAlarmsEnabled(params?.AlarmsEnabled === "True");
      setAllocatedStorage(parseInt(params?.AllocatedStorageSize) || 10);
      setBackupRetentionPeriod(parseInt(params?.BackupRetention) || 0);
      setLogRetentionDays(parseInt(params?.LogRetentionDays) || 0);
      setCreateReplica(params?.CreateReplica === "True");
    }
  }, [getMyDbServiceStackStatusData]);

  const [getMyDbFamilies, { loading: getMyDbFamiliesLoading }] =
    useGetMyDbFamiliesLazyQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
      onCompleted: (data) => {
        setAvailableFamilies(data.getMyDbFamilies || []);
      },
    });

  useEffect(() => {
    if (dbType !== "") {
      getMyDbFamilies({
        variables: {
          deploymentId: deployment.id,
          dbType,
        },
      });
    }
  }, [dbType, getMyDbFamilies, deployment.id]);

  const [getMyDbEngineVersions, { loading: getMyDbEngineVersionsLoading }] =
    useGetMyDbEngineVersionsLazyQuery({
      client: bgwService.getClient(),
      onCompleted: (data) => {
        setAvailableEngineVersions(data.getMyDbEngineVersions || []);
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  useEffect(() => {
    if (family !== "") {
      getMyDbEngineVersions({
        variables: {
          deploymentId: deployment.id,
          dbType,
          family,
        },
      });
    }
  }, [dbType, family, getMyDbEngineVersions, deployment.id]);

  const [getMyDbInstanceClasses, { loading: getMyDbInstanceClassesLoading }] =
    useGetMyDbInstanceClassesLazyQuery({
      client: bgwService.getClient(),
      onCompleted: (data) => {
        setAvailableInstanceClasses(data.getMyDbInstanceClasses || []);
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  useEffect(() => {
    if (dbType !== "" && engineVersion !== "") {
      getMyDbInstanceClasses({
        variables: {
          deploymentId: deployment.id,
          dbType,
          engineVersion,
        },
      });
    }
  }, [dbType, engineVersion, getMyDbInstanceClasses, deployment.id]);

  const { loading: getMyDbSnapshotIdsLoading } = useGetMyDbSnapshotIdsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
    },
    onCompleted: (data) => {
      setAvailableSnapshotIds(data.getMyDbSnapshotIds || []);
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const [removeMyDbStack, { loading: removeMyDbStackLoading }] =
    useRemoveMyDbStackMutation({
      client: bgwService.getClient(),
    });

  const removeDbService = () => {
    setMutationInProgress(true);
    removeMyDbStack({
      variables: {
        stackId: null,
        deploymentId: deployment.id,
        databaseId: stack.id,
      },
      onCompleted: () => {
        getMyDbServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to remove stack to ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const amIRoot = whoAmIData?.whoAmI?.role === "ROOT" || false;

  return (
    <DeployDbServiceComponent
      loading={
        getMyDbServiceStackStatusLoading ||
        mutationInProgress ||
        deployMyDbStackLoading ||
        getMyDbFamiliesLoading ||
        getMyDbEngineVersionsLoading ||
        getMyDbInstanceClassesLoading ||
        getMyDbSnapshotIdsLoading ||
        removeMyDbStackLoading ||
        whoAmILoading
      }
      stack={stack}
      deployment={deployment}
      stackStatus={
        getMyDbServiceStackStatusData?.getMyDbServiceStackStatus || null
      }
      queryError={queryError}
      removeQueryError={removeQueryError}
      deployDbService={deployDbService}
      refetchStackStatus={getMyDbServiceStackStatusRefetch}
      availableSnapshotIds={availableSnapshotIds}
      availableFamilies={availableFamilies}
      availableEngineVersions={availableEngineVersions}
      availableInstanceClasses={availableInstanceClasses}
      dbType={dbType}
      setDbType={setDbType}
      family={family}
      setFamily={setFamily}
      engineVersion={engineVersion}
      setEngineVersion={setEngineVersion}
      instanceClass={instanceClass}
      setInstanceClass={setInstanceClass}
      snapshotId={snapshotId}
      setSnapshotId={setSnapshotId}
      multiAz={multiAz}
      setMultiAz={setMultiAz}
      alarmsEnabled={alarmsEnabled}
      setAlarmsEnabled={setAlarmsEnabled}
      allocatedStorage={allocatedStorage}
      setAllocatedStorage={setAllocatedStorage}
      backupRetentionPeriod={backupRetentionPeriod}
      setBackupRetentionPeriod={setBackupRetentionPeriod}
      logRetentionDays={logRetentionDays}
      setLogRetentionDays={setLogRetentionDays}
      createReplica={createReplica}
      setCreateReplica={setCreateReplica}
      removeDbService={removeDbService}
      amIRoot={amIRoot}
    />
  );
};
