import PersonIcon from "@mui/icons-material/Person";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardActions from "@mui/joy/CardActions";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import { PageFrame } from "../../components/page_frame";
import { PageTitle } from "../../components/page_title";
import {
  Organization,
  User,
} from "../../services/backend_gateway/__generated__/backend_gateway-types";

export const ProfileComponent = (props: {
  loading: boolean;
  user: User | null;
  organization: Organization | null;
  signOut: () => void;
}) => {
  const { loading, user, organization, signOut } = props;

  return (
    <PageFrame>
      <PageTitle>My profile</PageTitle>

      {loading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Card
        variant="outlined"
        sx={{
          width: 320,
          // to make the card resizable
          overflow: "auto",
          resize: "horizontal",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {user?.picture ? (
            <Avatar src="/static/images/avatar/1.jpg" size="lg" />
          ) : (
            <Avatar size="lg">
              <PersonIcon />
            </Avatar>
          )}
        </Box>
        <CardContent>
          {user?.name && <Typography level="title-lg">{user?.name}</Typography>}
          <Typography level="body-sm">{user?.email}</Typography>
          <Typography level="body-sm">{organization?.name}</Typography>
        </CardContent>
        <CardActions buttonFlex="0 1 120px">
          <Button startDecorator={<Logout />} onClick={() => signOut()}>
            Logout
          </Button>
        </CardActions>
      </Card>
    </PageFrame>
  );
};
