import { useContext, useEffect, useMemo, useState } from "react";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import {
  useAddMyServiceStackMutation,
  useDeployMyEcsFargateStackMutation,
  useGetMyDeploymentsQuery,
  useGetMyEcsServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
  useGetMyServiceStacksQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { AlertType } from "../../../contexts/alerts/type";
import { AddServiceComponent } from "./component";

export const AddServiceContainer = (props: {
  deploymentId: string;
  navigateToNext: null | (() => void);
  navigateToPrevious: null | (() => void);
  navigateWhenFinished: () => void;
}) => {
  const {
    deploymentId,
    navigateToNext,
    navigateToPrevious,
    navigateWhenFinished,
  } = props;
  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const [stackId, setStackId] = useState<string>("");

  const { data: getMyDeploymentsData, loading: getMyDeploymentsLoading } =
    useGetMyDeploymentsQuery({
      client: bgwService.getClient(),
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const deployment = useMemo(
    () =>
      getMyDeploymentsData?.getMyDeployments?.find(
        (d) => d.id === deploymentId
      ),
    [getMyDeploymentsData, deploymentId]
  );

  const {
    data: getMyServiceStacksQueryData,
    loading: getMyServiceStacksQueryLoading,
  } = useGetMyServiceStacksQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const [
    addMyServiceStackMutation,
    { loading: addMyServiceStackMutationLoading },
  ] = useAddMyServiceStackMutation({
    client: bgwService.getClient(),
    variables: {
      stackId,
      type: "ECS",
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const {
    data: getMyEcsServiceStackStatusData,
    loading: getMyEcsServiceStackStatusLoading,
    refetch: getMyEcsServiceStackStatusRefetch,
  } = useGetMyEcsServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deploymentId,
      pipelineId: stackId,
    },
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const { data: getMyGitConnectionsData, loading: getMyGitConnectionsLoading } =
    useGetMyGitConnectionsQuery({
      client: bgwService.getClient(),
      variables: {
        deploymentId: deploymentId,
      },
      onError: (error) => {
        if (error?.message !== undefined) {
          addAlert({
            text: error.message!,
            type: AlertType.DANGER,
          });
        }
      },
    });

  const [
    deployMyEcsFargateStackMutation,
    { loading: deployMyEcsFargateStackLoading },
  ] = useDeployMyEcsFargateStackMutation({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.DANGER,
        });
      }
    },
  });

  const deployEcsService = async (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    dockerfilePath: string,
    servicePort: number,
    healthCheckPath: string | undefined
  ) => {
    await addMyServiceStackMutation();
    deployMyEcsFargateStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stackId,
          deploymentId: deploymentId,
          instanceCount: 0,
          gitConnectionArn,
          gitRepositoryId,
          gitBranch,
          dockerfilePath,
          runtimeJson: "",
          buildtimeJson: "",
          ecsTaskCpu: 256,
          ecsTaskRam: 512,
          servicePort,
          healthCheckPath,
          healthCheckPort: undefined,
          logRetentionDays: undefined,
          alarmsEnabled: undefined,
          apiKeyRequired: true,
          iamAuthRequired: false,
          cicdManualApproval: undefined,
        },
      },
      onCompleted: (data) => {
        getMyEcsServiceStackStatusRefetch();
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus?.status ||
          ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyEcsServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyEcsServiceStackStatusData, getMyEcsServiceStackStatusRefetch]);

  return (
    <AddServiceComponent
      loading={
        getMyDeploymentsLoading ||
        addMyServiceStackMutationLoading ||
        getMyServiceStacksQueryLoading ||
        getMyGitConnectionsLoading ||
        getMyEcsServiceStackStatusLoading ||
        deployMyEcsFargateStackLoading
      }
      stacks={getMyServiceStacksQueryData?.getMyServiceStacks || []}
      deployment={deployment || null}
      stackStatus={
        getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus || null
      }
      gitConnectionArns={getMyGitConnectionsData?.getMyGitConnections || []}
      deployEcsService={deployEcsService}
      refetchStackStatus={() => {
        getMyEcsServiceStackStatusRefetch();
      }}
      stackId={stackId}
      setStackId={setStackId}
      navigateToNext={navigateToNext}
      navigateToPrevious={navigateToPrevious}
      navigateWhenFinished={navigateWhenFinished}
    />
  );
};
