import Box from "@mui/joy/Box";
// import Sheet from "@mui/joy/Sheet";
import { ReactNode } from "react";
import { LogoText } from "../../layouts/drawer_layout/drawer/logo_text";
import { colors } from "../../layouts/drawer_layout/colors";

export const LeanLayout = (props: { children?: ReactNode }) => {
  const { children } = props;
  return (
    <Box
      sx={{
        pt: 0,
        px: 0,
        overflow: "hidden",
        display: "grid",
        gridTemplateRows: "auto 120px",
        gridTemplateColumns: {
          xs: "auto",
          // sm: "64px auto 64px 50vw",
          // md: "1fr 640px 1fr 50vw",
          sm: "64px auto 64px",
          md: "1fr 640px 1fr",
        },
        bgcolor: colors.grey2,
        minHeight: "100vh",
      }}
    >
      {/* <Sheet
        variant="plain"
        color="neutral"
        sx={{
          p: 0,
          m: 0,
          display: {
            xs: "none",
            sm: "inherit",
          },
          gridRow: "span 2",
          gridColumn: {
            sm: 4,
          },
        }}
      >
        <iframe
          src="https://www.wafflesoc2.com/"
          title="What is Waffle?"
          // frameBorder="0"
          style={{
            border: "0px solid transparent",
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
          height="100%"
          width="100%"
        />
      </Sheet> */}
      <Box
        sx={{
          p: 4,
          mt: 4,
          gridRow: 1,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          py: 4,
          gridRow: 2,
          gridColumn: {
            xs: 1,
            sm: 2,
          },
        }}
      >
        <LogoText />
      </Box>
    </Box>
  );
};
