import { useContext, useEffect, useState } from "react";
import {
  Deployment,
  ServiceStack,
  useDeployMyEcsFargateStackMutation,
  useGetMyEcsServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
  useRemoveMyEcsFargateStackMutation,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployEcsServiceComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertsContext } from "../../../contexts/alerts/context";
import { AlertType } from "../../../contexts/alerts/type";

export const DeployEcsServiceContainer = (props: {
  deployment: Deployment;
  stack: ServiceStack;
}) => {
  const { deployment, stack } = props;
  const [queryError, setQueryError] = useState<string[]>([]);
  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);
  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    error: getMyGitConnectionsError,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
    },
  });

  useEffect(() => {
    if ((getMyGitConnectionsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyGitConnectionsError!.message]);
    }
  }, [getMyGitConnectionsError]);

  const {
    data: getMyEcsServiceStackStatusData,
    loading: getMyEcsServiceStackStatusLoading,
    error: getMyEcsServiceStackStatusError,
    refetch: getMyEcsServiceStackStatusRefetch,
  } = useGetMyEcsServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
      pipelineId: stack.id,
    },
  });

  useEffect(() => {
    if ((getMyEcsServiceStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyEcsServiceStackStatusError!.message]);
    }
  }, [getMyEcsServiceStackStatusError]);

  const [
    deployMyEcsFargateStackMutation,
    { loading: deployMyEcsFargateStackLoading },
  ] = useDeployMyEcsFargateStackMutation({
    client: bgwService.getClient(),
  });

  const deployEcsService = (
    instanceCount: number,
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    dockerfilePath: string,
    runtimeJson: string,
    buildtimeJson: string,
    ecsTaskCpu: number,
    ecsTaskRam: number,
    servicePort: number,
    healthCheckPath: string,
    healthCheckPort: number,
    logRetentionDays: number,
    alarmsEnabled: boolean,
    apiResourceEnabled: boolean,
    apiKeyRequired: boolean,
    iamAuthRequired: boolean,
    cicdManualApproval: boolean,
    mapToRootPath: boolean
  ) => {
    setMutationInProgress(true);
    deployMyEcsFargateStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stack.id,
          deploymentId: deployment.id,
          instanceCount,
          gitConnectionArn,
          gitRepositoryId,
          gitBranch,
          buildspecPath,
          dockerfilePath,
          runtimeJson,
          buildtimeJson,
          ecsTaskCpu,
          ecsTaskRam,
          servicePort,
          healthCheckPath,
          healthCheckPort,
          logRetentionDays,
          alarmsEnabled,
          apiResourceEnabled,
          apiKeyRequired,
          iamAuthRequired,
          cicdManualApproval,
          mapToRootPath,
        },
      },
      // update(cache, { data }) {
      //   cache.modify({
      //     fields: {
      //       getMyEcsServiceStackStatus(existingnewStackStatuses = []) {
      //         const newStackStatuskRef = cache.writeFragment({
      //           data: data?.deployMyEcsFargateStack,
      //           fragment: gql`
      //             fragment NewStackStatus on StackStatus {
      //               status
      //               parametersJSON
      //             }
      //           `,
      //         });
      //         return [...existingnewStackStatuses, newStackStatuskRef];
      //       },
      //     },
      //   });
      // },
      onCompleted: (data) => {
        getMyEcsServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to deploy stack to ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus?.status ||
          ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyEcsServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyEcsServiceStackStatusData, getMyEcsServiceStackStatusRefetch]);

  const [removeMyEcsFargateStack, { loading: removeMyEcsFargateStackLoading }] =
    useRemoveMyEcsFargateStackMutation({
      client: bgwService.getClient(),
    });

  const removeEcsService = () => {
    setMutationInProgress(true);
    removeMyEcsFargateStack({
      variables: {
        stackId: null,
        deploymentId: deployment.id,
        pipelineId: stack.id,
      },
      onCompleted: () => {
        getMyEcsServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to remove stack from ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const amIRoot = whoAmIData?.whoAmI?.role === "ROOT" || false;

  return (
    <DeployEcsServiceComponent
      loading={
        getMyGitConnectionsLoading ||
        getMyEcsServiceStackStatusLoading ||
        deployMyEcsFargateStackLoading ||
        removeMyEcsFargateStackLoading ||
        mutationInProgress ||
        whoAmILoading
      }
      stack={stack}
      deployment={deployment}
      stackStatus={
        getMyEcsServiceStackStatusData?.getMyEcsServiceStackStatus || null
      }
      gitConnectionArns={getMyGitConnectionsData?.getMyGitConnections || []}
      queryError={queryError}
      removeQueryError={removeQueryError}
      deployEcsService={deployEcsService}
      refetchStackStatus={() => {
        getMyEcsServiceStackStatusRefetch();
      }}
      removeEcsService={removeEcsService}
      amIRoot={amIRoot}
    />
  );
};
