import { useContext, useEffect, useState } from "react";
import {
  Deployment,
  ServiceStack,
  useDeployMyCfnStackMutation,
  useGetMyCfnServiceStackStatusQuery,
  useGetMyGitConnectionsQuery,
  useRemoveMyCfnStackMutation,
  useWhoAmIQuery,
} from "../../../services/backend_gateway/__generated__/backend_gateway-types";
import { DeployCfnServiceComponent } from "./component";
import { BgwContext } from "../../../contexts/backend_gateway/context";
import { AlertType } from "../../../contexts/alerts/type";
import { AlertsContext } from "../../../contexts/alerts/context";

export const DeployCfnServiceContainer = (props: {
  deployment: Deployment;
  stack: ServiceStack;
}) => {
  const { deployment, stack } = props;
  const [queryError, setQueryError] = useState<string[]>([]);
  const [mutationInProgress, setMutationInProgress] = useState<boolean>(false);
  const removeQueryError = (i: number) => {
    setQueryError((e) => {
      const newErrors = [...e];
      newErrors.splice(i, 1);
      return newErrors;
    });
  };

  const { bgwService } = useContext(BgwContext);
  const { addAlert } = useContext(AlertsContext);

  const {
    data: getMyGitConnectionsData,
    loading: getMyGitConnectionsLoading,
    error: getMyGitConnectionsError,
  } = useGetMyGitConnectionsQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
    },
  });

  useEffect(() => {
    if ((getMyGitConnectionsError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyGitConnectionsError!.message]);
    }
  }, [getMyGitConnectionsError]);

  const {
    data: getMyCfnServiceStackStatusData,
    loading: getMyCfnServiceStackStatusLoading,
    error: getMyCfnServiceStackStatusError,
    refetch: getMyCfnServiceStackStatusRefetch,
  } = useGetMyCfnServiceStackStatusQuery({
    client: bgwService.getClient(),
    variables: {
      deploymentId: deployment.id,
      pipelineId: stack.id,
    },
  });

  useEffect(() => {
    if ((getMyCfnServiceStackStatusError?.message || null) !== null) {
      setQueryError((e) => [...e, getMyCfnServiceStackStatusError!.message]);
    }
  }, [getMyCfnServiceStackStatusError]);

  const [deployMyCfnStackMutation, { loading: deployMyCfnStackLoading }] =
    useDeployMyCfnStackMutation({
      client: bgwService.getClient(),
    });

  const deployCfnService = (
    gitConnectionArn: string,
    gitRepositoryId: string,
    gitBranch: string,
    buildspecPath: string,
    buildtimeJson: string
  ) => {
    deployMyCfnStackMutation({
      variables: {
        stackDeploymentInput: {
          id: stack.id,
          deploymentId: deployment.id,
          gitConnectionArn,
          gitRepositoryId,
          gitBranch,
          buildspecPath,
          buildtimeJson,
        },
      },
      // update(cache, { data }) {
      //   cache.modify({
      //     fields: {
      //       getMyCfnServiceStackStatus(existingnewStackStatuses = []) {
      //         const newStackStatuskRef = cache.writeFragment({
      //           data: data?.deployMyCfnStack,
      //           fragment: gql`
      //             fragment NewStackStatus on StackStatus {
      //               status
      //               parametersJSON
      //             }
      //           `,
      //         });
      //         return [...existingnewStackStatuses, newStackStatuskRef];
      //       },
      //     },
      //   });
      // },
      onCompleted: (data) => {
        getMyCfnServiceStackStatusRefetch();
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to deploy stack to ${deployment.id}.`,
        ]);
        console.error(error);
      },
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        (
          getMyCfnServiceStackStatusData?.getMyCfnServiceStackStatus?.status ||
          ""
        ).indexOf("IN_PROGRESS") > -1
      ) {
        getMyCfnServiceStackStatusRefetch();
      }
    }, 8000);
    return () => clearInterval(intervalId);
  }, [getMyCfnServiceStackStatusData, getMyCfnServiceStackStatusRefetch]);

  const [removeMyCfnStack, { loading: removeMyCfnStackLoading }] =
    useRemoveMyCfnStackMutation({
      client: bgwService.getClient(),
    });

  const removeCfnService = () => {
    setMutationInProgress(true);
    removeMyCfnStack({
      variables: {
        stackId: null,
        deploymentId: deployment.id,
        pipelineId: stack.id,
      },
      onCompleted: () => {
        getMyCfnServiceStackStatusRefetch();
        setMutationInProgress(false);
      },
      onError: (error) => {
        setQueryError((e) => [
          ...e,
          `Failed to remove stack from ${deployment.id}.`,
        ]);
        console.error(error);
        setMutationInProgress(false);
      },
    });
  };

  const { data: whoAmIData, loading: whoAmILoading } = useWhoAmIQuery({
    client: bgwService.getClient(),
    onError: (error) => {
      if (error?.message !== undefined) {
        addAlert({
          text: error.message!,
          type: AlertType.WARNING,
        });
      }
    },
  });
  const amIRoot = whoAmIData?.whoAmI?.role === "ROOT" || false;

  return (
    <DeployCfnServiceComponent
      loading={
        getMyGitConnectionsLoading ||
        getMyCfnServiceStackStatusLoading ||
        deployMyCfnStackLoading ||
        removeMyCfnStackLoading ||
        mutationInProgress ||
        whoAmILoading
      }
      stack={stack}
      deployment={deployment}
      stackStatus={
        getMyCfnServiceStackStatusData?.getMyCfnServiceStackStatus || null
      }
      gitConnectionArns={getMyGitConnectionsData?.getMyGitConnections || []}
      queryError={queryError}
      removeQueryError={removeQueryError}
      deployCfnService={deployCfnService}
      refetchStackStatus={() => {
        getMyCfnServiceStackStatusRefetch();
      }}
      removeCfnService={removeCfnService}
      amIRoot={amIRoot}
    />
  );
};
